html {
  scroll-padding-top: 4rem;
  }
.jumbotron{
  margin: 3em 0 4em!important;
}
.ico-list.cyan li a:before {
  background-color: #00bcd4!important;
}

#carousel-hero li .ms-list-text.text-small {
  font-size: 14px;
  line-height: 1.8em;
}
.ms-hero.ms-hero-material:before {
  display: none;
}
.ms-hero.ms-hero-material {
  background-color: #ffffff;
  background: url("img/2023/img-portada-mapa-UE.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.ms-hero-material-list {
  /*color: #333;*/
}
.ms-hero-img-city {
  background-image: url("img/2023/img-portada-mapa-UE.jpg");
}
.ms-hero-bg-primary:after {
  background-color: rgba(102, 184, 230, 0.5);
}
.ms-slidebar .ms-slidebar-header {
  background-image: url("img/2023/img-portada-mapa-UE.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.ms-slidebar .ms-slidebar-header .ms-slidebar-title {
  background-color: rgba(255, 255, 255, 0.7);
  overflow: visible;
}
.sombra {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.ms-collapse .panel-info .panel-heading {
  background-color: #DC004E;
}
.text-left.card-block h4 {
  line-height: 1.5em;
}
.text-left.card-block {
  line-height: 1.5em;
}
.posicion_socialmedia {
  position: absolute;
  bottom: 40px;
}
#twitter-feed,
.footer-widget {
  /*background-color:#fff;*/
  padding-top: 20px;
  padding-bottom: 0px;
  border-bottom: 1px solid #f2f3f5;
}
.footer-img {
  width: 72px;
  height: 72px;
}

#twitter-feed a {
  color: #00aeef;
}

#twitter-feed h2,
#twitter-feed img {
  display: inline-block;
}

#twitter-feed h2 {
  margin-bottom: 35px;
}

#twitter-feed img {
  margin-right: 5px;
  /*margin-top:-10px;*/
}
.owl-carousel .owl-item img {
  width: initial;
}
.carousel-caption {
  text-shadow: 0 1px 2px rgba(0, 0, 0, 1);
}

.posicio-quarking {
  margin-top: 50%;
  transform: translate(0, -25%);
  text-align: center;
}
#quarking_container {
  /*margin-top: 50%;
    transform: translate(0, -25%);*/
}
#quarking_container #quarking_hermes_qr img {
  display: block;
  margin: 0 auto;
}

.site-footer {
  font-size: 14px;
  min-height: 200px;
  background-color: #edeeee;
  margin-top: 30px;
  padding: 15px 0 30px;
}
.site-footer address a,
.site-footer .contact-devider a {
  color: #333333;
  text-decoration: none;
}
.link-in-textcolor a:visited,
.contact-info a:visited,
.site-footer address a:visited,
.site-footer .contact-devider a:visited {
  color: #333333;
}
.link-in-textcolor a:hover,
.link-in-textcolor a:focus,
.link-in-textcolor a:active,
.contact-info a:hover,
.contact-info a:focus,
.contact-info a:active,
.site-footer address a:hover,
.site-footer address a:focus,
.site-footer address a:active,
.site-footer .contact-devider a:hover,
.site-footer .contact-devider a:focus,
.site-footer .contact-devider a:active {
  outline: 0;
  color: #0092bc;
  text-decoration: none;
}
.site-footer .img-wrap-ec a > img,
.site-footer .img-wrap-ec > img {
  width: 100%;
  height: auto;
}
.site-footer .img-wrap-ern a > img,
.site-footer .img-wrap-ern > img {
  width: 100%;
  height: auto;
}
.site-footer .caption-ec-ern-logo {
  text-align: center;
  margin-top: 30px;
  padding-top: 10px;
  border-top: solid 2px #fff;
  font-size: 12px;
}
.site-footer .footermenu {
  margin-top: 20px;
  text-align: right;
}
.site-footer li {
  list-style: none;
}
.site-footer .footermenu h1 {
  color: #DC004E;
  font-weight: 300;
  font-size: 18px;
  margin-bottom: 2px;
  margin-top: 23px;
}
.site-footer .main-contact-info {
  margin-top: 23px;
}
.site-footer .main-contact-info .name {
  color: #DC004E;
  font-weight: 300;
  font-size: 18px;
}
.site-footer .footermenu a {
  color: #333333;
}
.site-footer .footermenu a:hover {
  color: #DC004E;
}

.form-padding {
  padding: 0 30px;
}
.form-horizontal .row {
  /*margin: 0;*/
}
.img-avatar {
  /*border: 1px solid #ccc;*/
  /*margin-top: 8px;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    box-shadow: 0 5px 10px rgba(0,0,0,0.25);
    */
  margin-bottom: 14px;
}

.img-avatar-small {
  width: 48px;
  height: 48px;
  max-width: 48px;
  max-height: 48px;
  border-radius: 50%;
  border: solid 3px #fff;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.2),
    0 1px 8px 0 rgba(0, 0, 0, 0.12);
  margin: 0 auto;
  margin-top: 0px;
  margin-bottom: 0px;
  display: block;
  margin-top: -5px;
  margin-bottom: -10px;
}

/*select with tags */
.fstChoiceItem {
  padding: 2px 15px 2px 25px;
}
.fstElement,
.fstControls {
  width: 100% !important;
}
.fstElement {
  box-shadow: none;
  border: 0px;
  background-color: transparent;
}
.fstChoiceItem {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: 2px;
  font-weight: 400;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.84);
}
.fstChoiceRemove {
  left: 5px;
}

.form-group.label-static label.control-label-2,
.form-group.label-placeholder label.control-label-2,
.form-group.label-floating label.control-label-2 {
  transition: 0.3s ease all;
}
.form-group.label-floating label.control-label-2,
.form-group.label-placeholder label.control-label-2 {
  top: -7px;
  font-size: 12px;
  line-height: 1.42857;
}
.form-group.label-static label.control-label-2,
.form-group.label-floating.is-focused label.control-label-2,
.form-group.label-floating:not(.is-empty) label.control-label-2 {
  top: -30px;
  left: 0;
  font-size: 16px;
  line-height: 1.07143;
}

.fstElement {
  border: 0;
  background-image: linear-gradient(#DC004E, #DC004E),
    linear-gradient(#d2d2d2, #d2d2d2);
  background-size: 0 2px, 100% 1px;
  background-repeat: no-repeat;
  background-position: center bottom, center calc(100% - 1px);
  background-color: transparent;
  transition: background 0s ease-out;
  float: none;
  box-shadow: none;
  border-radius: 0;
  height: auto;
  width: 100%;
}
.fstElement.fstActive {
  outline: none;
  background-image: linear-gradient(#DC004E, #DC004E),
    linear-gradient(#d2d2d2, #d2d2d2);
  background-size: 100% 2px, 100% 1px;
  box-shadow: none;
  transition-duration: 0.3s;
}

.paginacio-formularis {
  text-align: center;
}
.paginacio-formularis div {
  display: inline-block;
}
.paginacio-formularis::after {
  content: " ";
  position: relative;
  bottom: 51px;
  background-color: #f44336;
  height: 2px;
  width: 100%;
  display: block;
  z-index: 1;
}
.paginacio-formularis ul {
  list-style: none;
  padding: 0;
  display: flex;
  box-sizing: border-box;
}

.paginacio-formularis ul li {
  /*float: left;*/
  width: 50px;
  height: 50px;
  border-radius: 50%;
  color: #fff;
  font-size: 20px;
  text-align: center;
  padding: 12px;
  background-color: #f44336;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  margin: 0 20px;
  z-index: 2;
}

.paginacio-formularis ul li.active {
  width: 80px;
  height: 80px;
  margin-top: -15px;
  padding: 26px;
  font-size: 30px;
  font-weight: bold;
  background: white;
  border: 2px solid #f44336;
  color: #f44336;
}

.color-secondary {
  color: #004b91 !important; /*4fc3f7*/
}

.paginacio-formularis.level {
  text-align: right;
}

.paginacio-formularis.level::after {
  bottom: 35px;
  background-color: #004b91; /*4fc3f7*/
  height: 1px;
}

.paginacio-formularis.level ul li {
  width: 40px;
  height: 40px;
  color: #fff;
  font-size: 15px;
  padding: 8px;
  margin: 0 10px;
  margin-top: 10px !important;
  background-color: #004b91; /*4fc3f7*/
  z-index: 2;
}

.paginacio-formularis.level ul li.active {
  width: 50px;
  height: 50px;
  padding: 10px;
  margin-top: 5px !important;
  font-size: 20px;
  border: 2px solid #004b91; /*4fc3f7*/
}

.form-group.label-static label.control-label,
.form-group.label-floating.is-focused label.control-label,
.form-group.label-floating:not(.is-empty) label.control-label {
  font-size: 14px;
  line-height: 0.8;
  font-weight: bold;
}
label.control-label-noanimated {
  color: rgba(0, 0, 0, 0.87) !important;
  top: -30px;
  left: 0;
  font-size: 14px !important;
  font-weight: bold;
  /*line-height: 1.07143 !important;*/
  position: absolute;
  pointer-events: none;
  transition: 0.3s ease all;
  margin: 22px 0 0;
}
label.control-label-noanimated.smaller {
  font-size: 11px !important;
  font-weight: normal;
}
.mapa_fix {
  position: fixed;
  top: 80px;
  /*width: 36.7%;*/
  width: 606px;
  z-index: 99;
}

tr.table-link {
  cursor: pointer;
}

tr.table-link a {
  color: rgb(117, 117, 117);
}

span.required label:after {
  content: " *";
  color: red;
}
.form-group .help-block {
  display: block;
}
.pkdeep-aclaracion {
  color: #DC004E;
  font-size: 14px;
}
.hint-block {
  font-size: 12px;
  margin-bottom: -10px;
}
.card-subtitle {
  background-color: #941e1e;
  color: white;
}
.card-subtitle h4 {
  margin: 0;
  padding: 25px;
}
.card-subtitle .img-avatar-small {
  margin-top: 10px;
  margin-bottom: 0;
}
.fstResultItem {
  font-size: 14px;
}
.fstMultipleMode .fstQueryInput {
  font-size: 14px;
}
.alert-rosat {
  margin: 0;
  background-color: #fdd;
  color: #666;
}
.ms-hero-img-room {
  background-image: url(../img/Bakground-RADeep.png);
}
.dropdown-submenu .dropdown-menu-left li.active {
  background-color: #2db7f5 !important;
}
.ms-hero-img-radeep {
  background-image: url(../img/background_radeep_interior.jpg);
}
.boto-slider {
  z-index: 16;
  white-space: nowrap;
  font-size: 14px;
  line-height: 43px;
  font-weight: 700;
  color: rgb(255, 255, 255);
  font-family: Arial, Helvetica, sans-serif;
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(255, 255, 255, 0.25);
  border-style: solid;
  border-width: 2px;
  border-radius: 4px;
  outline: none;
  box-shadow: none;
  box-sizing: border-box;
  letter-spacing: 4px;
  cursor: pointer;
  visibility: inherit;
  text-align: left;
  margin: 0px;
  padding: 15px 47px;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  opacity: 1;
  transform-origin: 50% 50% 0px;
  transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  -webkit-font-smoothing: antialiased;
  transition: background-color 0.5s, color 0.5s !important;
}
.boto-slider:hover {
  background-color: white;
  color: black;
}

.btn-circle.btn-circle-primary2 {
  color: #DC004E;
}
.btn-circle.btn-circle-primary2:before {
  background-color: #DC004E;
}
.btn-circle.btn-circle-primary2:hover,
.btn-circle.btn-circle-primary2:focus {
  color: #fff;
}
.btn-circle.btn-circle-raised.btn-circle-white.btn-circle-primary2 {
  color: #DC004E;
}
.btn-circle.btn-circle-raised.btn-circle-primary2 {
  background-color: #DC004E;
}
.btn-circle.btn-circle-raised.btn-circle-primary2:before {
  background-color: #b12424;
}
.ms-header.ms-header-dark .btn-circle-primary2 {
  color: #fff;
}
.ms-header.ms-header-dark .btn-circle-primary2:before {
  background-color: #DC004E;
}
.ms-header.ms-header-dark .btn-circle-primary2:hover,
.ms-header.ms-header-dark .btn-circle-primary2:focus {
  color: #fff;
}
.ms-header .header-right .search-form2 {
  vertical-align: top;
  display: inline-block;
  position: relative;
  height: 50px;
  min-width: 50px;
  padding: 0;
  margin: 0;
  transition: width ease 1s;
  z-index: 0;
}
.ms-header .header-right .search-form2 #search-submit {
  position: relative;
  left: -5000px;
  width: 0;
  padding: 0;
  border: 0;
  height: 0;
}
.ms-header .header-right .search-form2 label {
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  font-size: 20px;
  color: #DC004E;
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 3;
  transition: -webkit-transform ease 0.5s;
  transition: transform ease 0.5s;
  transition: transform ease 0.5s, -webkit-transform ease 0.5s;
  transition-delay: 0.2s;
}
.ms-header .header-right .search-form2 label:before {
  transition: all ease 0.5s;
  border-radius: 150px;
  z-index: -1;
  content: "";
  position: absolute;
  display: block;
  background-color: #DC004E;
  height: 0;
  width: 0;
  top: 25px;
  left: 25px;
}
.ms-header .header-right .search-form2 label:hover {
  color: #fff;
}
.ms-header .header-right .search-form2 label:hover:before {
  height: 50px;
  width: 50px;
  top: 0;
  left: 0;
}
.ms-header .header-right .search-form2 .search-input {
  height: 50px;
  border: 0;
  width: 0;
  padding: 0;
  background-color: transparent;
  display: inline-block;
  transition: width ease 0.5s;
  vertical-align: top;
  padding-left: 50px;
  border-radius: #616161;
  color: #000;
}
.ms-header
  .header-right
  .search-form2
  .search-input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.3);
}
.ms-header .header-right .search-form2 .search-input::-moz-placeholder {
  color: rgba(0, 0, 0, 0.3);
}
.ms-header .header-right .search-form2 .search-input:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.3);
}
.ms-header .header-right .search-form2 .search-input:-moz-placeholder {
  color: rgba(0, 0, 0, 0.3);
}
.ms-header .header-right .search-form2 .search-input:focus {
  width: 250px;
  border-bottom: solid 3px #DC004E;
  border-radius: 0;
}
.ms-header .header-right .search-form2 .search-input:focus + label {
  -webkit-transform: ScaleX(-1);
  transform: ScaleX(-1);
  color: #DC004E;
}
.ms-header .header-right .search-form2 .search-input:focus + label:before {
  transition: none;
  display: none;
}
.ms-header.ms-header-dark .search-form2 label {
  color: #fff;
}
.ms-header.ms-header-dark .search-form2 label:before {
  background-color: #DC004E;
}
.ms-header.ms-header-dark .search-form2 label:hover {
  color: #fff;
}
.ms-header.ms-header-dark .search-form2 .search-input {
  color: #fff;
  font-weight: 400;
}
.ms-header.ms-header-dark
  .search-form2
  .search-input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.ms-header.ms-header-dark .search-form2 .search-input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.ms-header.ms-header-dark .search-form2 .search-input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.ms-header.ms-header-dark .search-form2 .search-input:-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.ms-header.ms-header-dark .search-form2 .search-input:-webkit-autofill {
  -webkit-text-fill-color: #fff !important;
}
.ms-header.ms-header-dark .search-form2 .search-input:focus {
  border-bottom: solid 3px #DC004E;
}
.ms-header.ms-header-dark .search-form2 .search-input:focus + label {
  color: #fff;
}
.ms-header.ms-header-primary .search-form2 label {
  color: #fff;
}
.ms-header.ms-header-primary .search-form2 label:before {
  background-color: #fff;
}
.ms-header.ms-header-primary .search-form2 label:hover {
  color: #DC004E;
}
.ms-header.ms-header-primary .search-form2 .search-input {
  color: #fff;
  font-weight: 400;
}
.ms-header.ms-header-primary
  .search-form2
  .search-input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.ms-header.ms-header-primary .search-form2 .search-input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.ms-header.ms-header-primary .search-form2 .search-input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.ms-header.ms-header-primary .search-form2 .search-input:-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.ms-header.ms-header-primary .search-form2 .search-input:-webkit-autofill {
  -webkit-text-fill-color: #fff !important;
}
.ms-header.ms-header-primary .search-form2 .search-input:focus {
  border-bottom: solid 3px #fff;
}
.ms-header.ms-header-primary .search-form2 .search-input:focus + label {
  color: #fff;
}
.ms-slidebar .ms-slidebar-header .ms-slidebar-title .search-form2 {
  vertical-align: top;
  display: inline-block;
  position: relative;
  height: 50px;
  min-width: 50px;
  padding: 0;
  margin: 0;
  transition: width ease 1s;
  z-index: 0;
  float: right;
}
.ms-slidebar
  .ms-slidebar-header
  .ms-slidebar-title
  .search-form2
  #search-submit {
  position: relative;
  left: -5000px;
  width: 0;
  padding: 0;
  border: 0;
  height: 0;
}
.ms-slidebar .ms-slidebar-header .ms-slidebar-title .search-form2 label {
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  font-size: 20px;
  color: #fff;
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 3;
  transition: -webkit-transform ease 0.5s;
  transition: transform ease 0.5s;
  transition: transform ease 0.5s, -webkit-transform ease 0.5s;
  transition-delay: 0.2s;
}
.ms-slidebar
  .ms-slidebar-header
  .ms-slidebar-title
  .search-form2
  label:hover:before {
  height: 50px;
  width: 50px;
  top: 0;
  left: 0;
}
.ms-slidebar
  .ms-slidebar-header
  .ms-slidebar-title
  .search-form2
  .search-input {
  height: 50px;
  border: 0;
  width: 0;
  padding: 0;
  background-color: transparent;
  display: inline-block;
  transition: width ease 0.5s;
  vertical-align: top;
  padding-left: 50px;
  border-radius: #616161;
  color: #fff;
}
.ms-slidebar
  .ms-slidebar-header
  .ms-slidebar-title
  .search-form2
  .search-input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}
.ms-slidebar
  .ms-slidebar-header
  .ms-slidebar-title
  .search-form2
  .search-input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.7);
}
.ms-slidebar
  .ms-slidebar-header
  .ms-slidebar-title
  .search-form2
  .search-input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}
.ms-slidebar
  .ms-slidebar-header
  .ms-slidebar-title
  .search-form2
  .search-input:-moz-placeholder {
  color: rgba(0, 0, 0, 0.3);
}
.ms-slidebar
  .ms-slidebar-header
  .ms-slidebar-title
  .search-form2
  .search-input:-webkit-autofill {
  -webkit-text-fill-color: #fff !important;
}
.ms-slidebar
  .ms-slidebar-header
  .ms-slidebar-title
  .search-form2
  .search-input:focus {
  width: 300px;
  border-bottom: solid 3px #DC004E;
  border-radius: 0;
}
.ms-slidebar
  .ms-slidebar-header
  .ms-slidebar-title
  .search-form2
  .search-input:focus
  + label {
  -webkit-transform: ScaleX(-1);
  transform: ScaleX(-1);
  color: #DC004E;
}
.ms-slidebar
  .ms-slidebar-header
  .ms-slidebar-title
  .search-form2
  .search-input:focus
  + label:before {
  transition: none;
  display: none;
}

.color-primary-euroblood {
  color: #DC004E;
}
.color-primary-euroblood:hover,
.color-primary-euroblood:focus,
.color-primary-euroblood:active {
  color: #000;
}
.titulo-pkdeep {
  background-color: #4fc3f7;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #fff !important;
  text-transform: uppercase;
  padding: 5px 15px;
  margin: 35px 0 15px;
}

.ms-slidebar .ms-slidebar-header .ms-slidebar-login a {
  text-align: right;
}
.sb-slidebar ul.ms-slidebar-menu li a.active,
.sb-slidebar ul.ms-slidebar-menu li.active a {
  background-color: rgba(189, 37, 48, 0.2);
}

.sb-slidebar.menu-lateral-radeep ul.ms-slidebar-menu li a.active,
.sb-slidebar.menu-lateral-radeep ul.ms-slidebar-menu li.active a {
  background-color: rgba(45, 183, 245, 0.2);
}
.private-text {
  color: #aaa;
  font-size: 11px;
}
.usuario-no-activo {
  background-color: #ededed;
}
.tr-hover-primary:hover .td-hover-primary {
  color: #DC004E;
}
a.hover-primary:hover {
  color: #DC004E;
}

/* Always set the map height explicitly to define the size of the div
       * element that contains the map. */
#map {
  height: 100%;
}
/* Optional: Makes the sample page fill the window. */
.controls {
  margin-top: 10px;
  border: 1px solid transparent;
  border-radius: 2px 0 0 2px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  height: 32px;
  outline: none;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
}
#pac-input {
  background-color: #fff;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 300;
  margin-left: 12px;
  padding: 0 11px 0 13px;
  text-overflow: ellipsis;
  width: 300px;
}
#pac-input:focus {
  border-color: #4d90fe;
}
.pac-container {
  font-family: Roboto;
}
#type-selector {
  color: #fff;
  background-color: #4d90fe;
  padding: 5px 11px 0px 11px;
}
#type-selector label {
  font-family: Roboto;
  font-size: 13px;
  font-weight: 300;
}
.checkbox-lista-vertical {
  padding-top: 15px;
}
.checkbox-lista-vertical label {
  display: block;
  text-align: left;
  color: #555 !important;
  padding-bottom: 10px;
}
.checkbox-lista-vertical label input {
}
.linea_bottom {
  background-image: linear-gradient(#DC004E, #DC004E),
    linear-gradient(#d2d2d2, #d2d2d2);
  background-size: 0 2px, 100% 1px;
  background-repeat: no-repeat;
  background-position: center bottom, center calc(100% - 1px);
  background-color: transparent;
}

.fila-par {
  background-color: #edeeee;
}
.fila-impar {
  background-color: #ffffff;
}

/* XS */
@media (max-width: 767px) {
  .mapa_fix {
    width: auto;
    position: relative;
    top: 20px;
  }
}
/* SM */
@media (min-width: 768px) and (max-width: 991px) {
  .mapa_fix {
    width: 361px;
  }
}
/* MD */
@media (min-width: 992px) and (max-width: 1199px) {
  .mapa_fix {
    width: 489px;
  }
}
/* LG */
@media (min-width: 1200px) {
}

/* Estils afegits posteriorment per aconseguir el mateix aspecte en algunes parts de la web */

.ms-slidebar .ms-slidebar-menu > li > a.red,
.ms-slidebar .ms-slidebar-menu > li > a.vermell {
  background-color: #bd2530;
  color: white;
}

h2.card-title,
h3.card-title {
  background-color: #DC004E;
  color: #f5f5f5;
  font-size: 16px;
  padding: 1.5rem 2rem;
  width: 100%;
  margin-bottom: 0;
  font-weight: 400;
}
h2.card-title:before,
h3.card-title:before {
  font: normal normal normal 14px/1 "Material-Design-Iconic-Font";
  margin-right: 10px;
  width: 15px;
  height: 16px;
  font-size: 16px;
}
h2.card-title.academic:before,
h3.card-title.academic:before {
  content: "\f174";
}
h2.card-title.users:before,
h3.card-title.users:before {
  font: normal normal normal 14px/1 "FontAwesome" !important;
  content: "\f0c0";
}
h2.card-title.list:before,
h3.card-title.list:before {
  font: normal normal normal 14px/1 "FontAwesome" !important;
  content: "\f03a";
}
h2.card-title.building:before,
h3.card-title.building:before {
  font: normal normal normal 14px/1 "FontAwesome" !important;
  content: "\f0f7";
}
h2.card-title.user:before,
h3.card-title.user:before {
  font: normal normal normal 14px/1 "FontAwesome" !important;
  content: "\f007";
}
.card .card-block table {
  border-bottom: solid 3px #DC004E !important;
  border-radius: 3px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  width: 100%;
  margin-bottom: 2em;
}
.card .card-block table td,
.card .card-block table th {
  border: none;
  padding: 14px !important;
  font-weight: normal;
}
.card .card-block table th {
  background-color: #fcf8e3;
}

hr {
  border-style: dotted !important;
  border-top: 1px solid #ddd;
  margin: 25px 0;
}

p.correu:before {
  content: "\f003";
  font: normal normal normal 14px/1 FontAwesome;
  padding-right: 4px;
  color: #DC004E;
  font-size: 16px;
}

a.extern:after {
  content: "\f08e";
  font: normal normal normal 14px/1 FontAwesome;
  font-size: 15px;
  padding-left: 10px;
}

a.file:after {
  content: "\f1c1";
  font: normal normal normal 14px/1 FontAwesome;
  font-size: 15px;
  padding-left: 3px;
}

/* Slider RADeep */
.tp-caption {
  color: #fff;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 300;
  line-height: 21px;
  font-synthesis: 21px;
}
.tp-titol {
  font-size: 51px;
  line-height: 67px;
  font-weight: 700;
}
.rev_slider {
  width: 100%;
}

/* search expert */

.search-expert .fstResultItem {
  font-size: 0.9em;
}
.search-expert .ms-collapse .panel .panel-heading .panel-title a {
  line-height: 16px;
  padding: 10px 20px 10px 15px;
}
.search-expert .ms-collapse .panel .panel-heading .panel-title a:after {
  top: 1.2rem;
  right: 1.5rem;
}
/* Always set the map height explicitly to define the size of the div
   * element that contains the map. */
.search-expert #map {
  height: 600px;
}
.search-expert .checkboxes.form-group label {
  color: rgba(0, 0, 0, 0.87) !important;
  padding-right: 24px;
}
.search-expert .checkboxes span div {
  padding-top: 15px;
}

.card {
  .alert-dismissible {
    margin-top: 40px;
    .close {
      margin-top: -2.75rem;
    }
  }
}
#divNewHospital > .alert {
  margin-top: 30px;
}

.ms-paper > .row .ms-paper-content-container .ms-paper-content {
  padding-bottom: 60px;
  margin-bottom: 0;
}
.ms-paper-content {
  .alert-dismissible {
    .close {
      margin-top: -2.75rem;
    }
  }
}

.is-fileinput {
  label {
    display: block;
    text-align: center;
  }
  #inputFile {
    position: relative;
  }
}

.expert-diseases span::after {
  content: ", ";
}
.expert-diseases span:last-child::after {
  content: "";
  display: none;
}

.nav.nav-tabs.nav-tabs-5 li a {
  padding: 1.7rem 1rem;
}

#newsletter-subscription {
  h3 {
    margin-top: 0;
  }
  p.requiredFields {
    display: none;
  }
  .form1-elem2 {
    label {
      display: none;
    }
    .form-group {
      margin-top: 5px;
    }
  }
  label {
    font-size: 14px;
  }
}
.form-webinar {
  padding: 30px;
  background-color: #eee;
}
.form-webinar .form-group {
  margin: 0;
}
.form-webinar textarea.form-control {
  max-height: 100px;
}
.form-webinar label {
  color: #757575;
}

.form-webinar select {
  display: block;
  line-height: 1.3;
  padding: 0.6em 1.4em 0.5em 0em;
  width: 100%;
  max-width: 100%;
  margin: 0;
  margin-bottom: 15px;
  font-size: 16px;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #d2d2d2;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #eee;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
}
.form-webinar select::-ms-expand {
  display: none;
}
.form-webinar select:hover {
  border-color: #888;
}
.form-webinar select:focus {
  border-color: #aaa;
  box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7);
  box-shadow: 0 0 0 3px -moz-mac-focusring;
  color: #222;
  outline: none;
}
.form-webinar select option {
  font-weight: normal;
}

.select2-container {
  margin-bottom: 10px;
}

.select2-container .select2-selection--single {
  font-size: 16px;
}
.select2-container .select2-selection--single .select2-selection__rendered {
  border-bottom: 1px solid #d2d2d2;
  font-size: 16px;
  padding-left: 5px;
  padding-bottom: 10px;
  background-color: #eee;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
}
.select2-search--dropdown .select2-search__field {
  border: 1px solid #d2d2d2;
}

.flex-wrapper {
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 991px) {
  .flex-wrapper > div {
    width: 100%;
  }
}
.flex-wrapper .card {
  height: calc(100% - 4rem);
}
.flex-wrapper .card-block {
  height: 100%;
}
.flex-wrapper .card-block .row {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.flex-wrapper .card-block .btn {
  margin-top: auto;
  margin-left: auto;
}

/* arreglar menú */
@media screen and (min-width: 768px) {
  .bd-scroll .ms-navbar .navbar-nav > li > a {
    padding-left: 8px;
    padding-right: 8px;
  }
}
@media screen and (min-width: 1024px) {
  .bd-scroll .ms-navbar .navbar-nav > li > a {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media screen and (min-width: 1200px) {
  .bd-scroll .ms-navbar .navbar-nav > li > a {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.navbar-fixed-top.ms-navbar .navbar-nav li{
  font-size:.9em;
}
.navbar-fixed-top.ms-navbar.shrink .navbar-brand{
  position: absolute;
  left: 15px;
  font-size:.9em;
}
@media (min-width: 768px){
  /*.ms-navbar {
     height: auto;
  }*/
  .back-button{
        position: absolute;
        right: 15px;
    }
}
.form-webinar label.error,.form-group label.error{
  color:#DC004E!important;
  font-size: .85em;
}
.ms-site-title a{
  color:#fff;
}
h1.simple{
  line-height: 1;
  margin-bottom: 1em;
}
.panel-color{
  background-color: #f7f7f7;
}
.card-block a.large-link{
    word-break: break-all;
}
h3.list a{
  color:#000;
}
.introHero{
  color:#fff;
  font-size: 1.5em;
  max-width: 800px;
  margin: .5em auto 0;
}
.alert h3 {
	color:#757575;
}
.mt-0 {
	margin-top:0px;
}
.form-ok{
	&.form-ok-icon {
		position: relative;
		padding-left: 60px;
		i {
		position: absolute;
		left:0;
		top:0;
		font-size: 50px;
		}
	}
}

/* tercer nivell menú*/
.subdropdown {
	position: relative;
	overflow: visible !important;
}
.subdropdown .subdropdown-menu{
	display:none;
}
	.subdropdown.open .subdropdown-menu{
		display:block;
		position: absolute;
		padding:0;
		margin:0;
		top: 0;
		width: 218px;
		right: -218px;
		-webkit-box-shadow: 0 3px 3px rgba(0,0,0,.29),0 3px 3px rgba(0,0,0,.29);
		box-shadow: 0 3px 3px rgba(0,0,0,.29),0 3px 3px rgba(0,0,0,.29);
	}
		.subdropdown.open .subdropdown-menu li{
			padding:0;
			margin:0;
		}
			.subdropdown.open .subdropdown-menu li a{
				display: block;
				padding-top:8px !important;
				padding-bottom:8px !important;
			}

.nav .open > a, .nav .open > a:focus, .nav .open > a:hover {
	background-color: transparent;
}
.fullprogram {
  background-color: #DC004E;
  color: #fff;
  padding: 25px;
  font-size:18px;
  margin-top: 20px;
}
.fullprogam-form {
  color: #fff;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: light;
  padding: 10px 40px;
  border: 1px solid #fff;
  border-radius: 3px;
  margin-top: 15px;
  position: relative;
}
.fullprogram p {
  font-size: 18px;
  margin-bottom: 20px;
}
.fullprogam-form:after {
  content: '';
  background-image: url('img/ico-down.svg');
  position: absolute;
  width: 19px;
  height: 12px;
  top: 12px;
  right: 10px;
}
.fullprogam-form:hover {
  background-color: #fff;
  color: #DC004E;
}
.full-form {
  margin-top: 30px;
}
.full-form .form-webinar label {
  display: block;

}
.full-form .form-webinar .select2-container {
    width: 100% !important;
}
.hidden {
  display: none;
}
.ico-list {
  list-style: none;
  padding-left: 0;
  margin-top: 30px;
  text-align: center;
}
.ico-list li {
    display: inline-block;
    vertical-align:top;
    width: 32%;
    padding-left: 0;
    margin-bottom: 30px;
  }
.ico-list li a {
  color: #4a4a4a;
  font-size: 20px;
  max-width: 333px;
  display: block;
  min-height: 165px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
  padding: 20px;
  text-align: left;
  position: relative;
}
.ico-list li a:before {
  content: '';
  background-color: #ff9800;
  position: absolute;
  height: 35px;
  bottom:0;
  width: 100%;
  left: 0;
}
.ico-list li:nth-child(6n+1) a:before {
  background-color: #ff9800;
}
.ico-list li:nth-child(6n+2) a:before {
  background-color: #4caf50;
}
.ico-list li:nth-child(6n+3) a:before {
  background-color: #9c27b0;
}
.ico-list li:nth-child(6n+4) a:before {
  background-color: #00bcd4;
}
.ico-list li:nth-child(6n+5) a:before {
  background-color: #f44336;
}
.ico-list li:nth-child(6n+6) a:before {
  background-color: #DC004E;
}
.ico-list li a:hover::before {
  filter: brightness(80%);
}
.ico-list li a:after {
  content: '';
  background-image: url('img/arrow.svg');
  background-repeat: no-repeat;
  position: absolute;
  height: 21px;
  bottom: 6px;
  width: 25px;
  right: 20px;
}
.ico-list li a img {
  float: left;
  margin-right: 20px;
}


/* XS */
@media (max-width: 767px) {
  .ico-list li {
    width: 100%;
  }
  .ico-list li a {
    max-width:100%;
  }
}
/* SM */
@media (min-width: 768px) and (max-width: 991px) {
  .ico-list li {
    width: 48%;
  }
}
.btn-guidelines {
  color: #DC004E !important;
  border: 1px solid #DC004E;
  background-color: #fff !important;
}
.form-guidelines button.dropdown-toggle {
    padding-left: 0!important;
}
.form-guidelines button span {
    text-transform: none;

}
.list-guidelines {
  text-align: left;
}
@media (max-width: 767px) {
  .patients .card {
    /*min-height: 420px;*/
    min-height: 235px;
  }
}
/* Cookie Dialog */
#gdpr-cookie-message {
    position: fixed;
    right: 0;
    bottom: 50px;
    max-width: 508px;
    background-color: #FFF;
    opacity: 0.9;
    padding: 40px 50px;
    box-shadow: 0 6px 6px rgba(0,0,0,0.25);
    margin-left: 30px;
    z-index: 9999;
}
    #gdpr-cookie-message hr{
        margin: 10px 0;
    }
    #gdpr-cookie-message h4 {
        color: #000;
        font-size: 18px;
        font-weight: bolder;
        margin-bottom: 10px;
    }
    #gdpr-cookie-message h5 {
        color: var(--red);
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 10px;
    }
#gdpr-cookie-message p, #gdpr-cookie-message ul {
    color: #000;
    font-size: 15px;
    line-height: 1.5em;
}
#gdpr-cookie-message p:last-child {
    margin-bottom: 0;
    text-align: right;
}
#gdpr-cookie-message li {
    width: 49%;
    display: inline-block;
    margin-bottom: 1em;
}
#gdpr-cookie-message a {
    color: #DC004E !important;
    text-decoration: none;
    font-size: 15px;
    font-weight: bolder;
    padding-bottom: 2px;
    border-bottom: 1px dotted rgba(255,255,255,0.75);
    transition: all 0.3s ease-in;
}
    #gdpr-cookie-message a:hover {
        color: white;
        border-bottom-color: var(--red);
        transition: all 0.3s ease-in;
    }
    #gpdr-cookie-message a:focus{
        outline: none !important;
    }
#gdpr-cookie-message button,
button#ihavecookiesBtn {
    font-size: 14px;
    padding: 7px 20px;
    margin-left: 15px;
    cursor: pointer;
    transition: all 0.3s ease-in;
}
    #gdpr-cookie-message button#gdpr-cookie-accept{
        background-color: #DC004E;
        color: #FFF;
        border: none;
    }
         #gdpr-cookie-message button#gdpr-cookie-accept:hover{
            transition: all 0.3s ease-in;
            opacity: 0.5;
         }
    #gdpr-cookie-message button#gdpr-cookie-advanced{
        background: none;
        border: 1px solid #000;
        color: #000;
    }

#gdpr-cookie-message button:disabled {
    opacity: 0.3;
}
    #gdpr-cookie-message input[type="checkbox"] {
        float: none;
        margin-top: 0;
        margin-right: 5px;
        background: #e0e0e0;
        border-radius: 4px;
        width: 24px;
        height: 24px;
        opacity: 0.9;
        border: none;
        vertical-align: middle;
        margin-bottom: 0;
    }
        #gdpr-cookie-message input[type="checkbox"]:focus{
            outline: none !important;
        }
        #gdpr-cookie-message input[type="checkbox"]:hover{
            background: #000;
            cursor: pointer;
            border: 0;
        }
        #gdpr-cookie-message input[type="checkbox"]:checked{
            background: #000;
        }
        #gdpr-cookie-message input[type="checkbox"]:disabled{
            background: #979197;
        }
            #gdpr-cookie-message input[type="checkbox"]:disabled:hover{
                background: #979197;
                cursor: default !important;
            }
        #gdpr-cookie-message input[type="checkbox"]:checked:after {
            content:'';
            width: 17px;
            height: 14px;
            position: relative;
            top: 5px;
            left: 5px;
            display: block;
        }

@media (max-width: 490px) {
    #gdpr-cookie-message li{
        width: 100%;
    }
    #gdpr-cookie-message button#gdpr-cookie-advanced{
        margin-top: 1em;
    }
    #gdpr-cookie-message button{
        float: left;
    }
    .isiOS.isiPhone #gdpr-cookie-message {
        padding: 10px 20px;
    }
}


.navbar-fixed-top.ms-navbar.shrink .navbar-brand{
    left: 0;
    top: 0;
    margin-left: 0;
}
.ms-footer {
    text-align: left;
}
@media (min-width: 768px){
    .ms-footer .social-media{
        text-align: right;
    }
    .ms-footer .social-media p{
        display: inline;padding-right: 10px;
    }
    .ms-footer .social-media ul{
        float:right;margin-top: -3px;
    }
}


.social-media .list-inline-item a{
    display: inline-block;
    width: 32px;
    height: 32px;
    background: #fff;
    border-radius: 50%;
    vertical-align: bottom;
    position: relative;
    line-height: 32px;
    text-align: center;
}
.social-media .list-inline-item a svg{
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -8px;
    margin-left: -8px;
    fill: #DC004E;
}
.social-media .list-inline-item a:hover svg {
    fill: #000;
}
.social-media .list-inline-item a span{
    display: inline-block;
    width: 26px;
    height: 26px;
        font-size: 11px;
    line-height: 26px;
    letter-spacing: -.1em;
    font-weight: bold;
}
@media (min-width: 1270px){
  .container {
      width: 1230px;
  }
  .ms-navbar .navbar-nav li a {
    padding: 15px 13px;
  }
}
@media (min-width: 1360px){
  .container {
      width: 1320px;
  }
  .ms-navbar .navbar-nav li a {
    padding: 15px 17px;
  }
}
@media (min-width: 1500px){
  .container {
      width: 1420px;
  }
}
.container .card-info .img-responsive{
    margin: 0 auto;
}