.tab-button {
    margin-left: -1em;
    margin-right: -1em;
    margin-top: 1em;
    margin-bottom: 1em;

    & a {
        display: block;
        background: #ddd;
        color: #000;
        padding: 1.2rem;
        font-size: 1.3em;

        &:hover {
            background: #ccc;
        }
    }
}

.s{
    max-width: 400px;
    margin: 2rem auto;
    fieldset div {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }
    input {
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        padding: 1.5rem 0 1.5rem 1.5rem;
        border-radius: 4px 0 0 4px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: 0;
    }
    button {
        background-color: #fff;
        padding: 0 1.5rem;
        border-radius: 0 4px 4px 0;
        border: 0;
    }
} 
.s--home button svg {
margin-top: 5px;
width: 25px;
height: 25px;
}
#search-box{
    position: absolute;
    right: 33.5%;
    z-index: 3333;
    top: 0;
    fieldset{
        border: solid 1px silver;border-radius:3px
    }
}
#search-page{
    .list.cercador>*+* {
        margin-top: 1.5rem;
    }
    .list.cercador article {
        border-bottom: 1px solid #ddd;
        padding-bottom: 1.5rem;
    }
    .list.cercador .breadcrumbs {
        font-size: .875em;
        margin-left: 0;
        padding-left: 0;
    }
    .list.cercador .breadcrumbs li {
        display: inline;
    }
    .list.cercador .breadcrumbs li {
        padding-left: 0;
    }
    .list.cercador .breadcrumbs li::after {
        content: '>';
        margin-left: 0.375em;
        margin-right: 0.375em;
    }
    .list.cercador a {
        text-decoration: none;
    }
    .list.cercador h2 {
        font-size: 1.5em;
        font-weight: bold;
    }
    .list.cercador p {
        font-size: 1em;
    }
}

.btn-navbar-search {
    display: inline-block;    
    height: 50px;
    width: 50px;
    line-height: 50px;
    text-align: center;
    position: absolute;
    right: 0;
    top: 0;
    font-size: 21px;
    display: none;
}
@media (max-width: 767px){
    .btn-navbar-search {
        display: inline-block;
    }
}


