body,body .sb-site-container{
  background-color:#fff;
}
.color-primary{
  color:#DC004E!important;
}
.color-secondary{
  color:#03418C!important;
}
.color-black{
  color:#242424;
}
.btn-primary,.btn-circle.btn-circle-raised.btn-circle-primary{
  background-color:#DC004E!important;
}
.btn-secondary,.bg-secondary{
  background-color:#03418C!important;
  color: #fff!important;
}
.ms-header {
  min-height: 90px
}
.ms-navbar.ms-navbar-dark{
  box-shadow:none;
  background: transparent;
}
#navbar{
  border-bottom: solid 1px #fff;
}
.ms-navbar.ms-navbar-dark.navbar-fixed-top{
  background-color: #24246C;
}
body .container .jumbotron{
  color:#03418C;
  box-shadow: none;
  
  width: 85%;
  max-width: 820px;
  margin-left: auto!important;
  margin-right: auto!important;
  text-align: center;
  
}
body .container .jumbotron:before{
  content:'';
  background-image: url("img/2023/circles.svg");
  background-repeat: no-repeat;
  width: 100%;
 height: 25px;
 margin-bottom: .5em;
 background-position: top center;
    display: block;
  text-align: center;
}
.box,.card.card-info{
  border-radius: 14px;
  border: 1.5px solid rgba(3,65,140, 1);  
}
.box-rounded{
  border-radius: 14px;
  
}
.box-gradient{
background-color: #24246C;    
border: none;
background-image: linear-gradient(116.56505117707799deg, rgba(36, 36, 108, 1) 0%, rgba(151, 4, 64, 1) 100%);
  color:#fff;
}
.card.card-info{
  box-shadow: none;
}
.card.card-info h2{
  margin-top:0;
}

.card-hero{
  border-radius: 30px;
  box-shadow: none;
}

.ms-hero-img-euroblood {
  background-image: url("img/2023/background-header-pages.jpg");
  padding: 0 0 140px;
}
/* home */
.home h1.ms-title{
  margin: 0;
}
.home-hero{
  background-image: url("img/2023/img-portada-mapa-UE.jpg");
    /* Additional CSS properties */
    width: 100%;
    height: 100%;
    
    background-position: top center;
    background-color: #24246C;
    background-repeat: none;
}

.home-hero .sb-site-container,.home-hero .ms-header,.home-hero .card{
  background: transparent;
}
.home-hero .card{
  color:#fff;
  box-shadow: none;
}
.home-hero  .panel .panel-body{
  padding: 0;
}

.home-hero .highlight h2{
border-radius: 10px;
padding: .3em 1em;
text-transform: uppercase;
font-weight: 700;
font-size: 1.25em;
background-color: rgba(255,255,255, .3);
display: inline-block;
}
.home-hero .highlight h3{
font-size: 2.5em;
}
.home-hero .highlight .card p strong{
  font-weight: 500;
}
.home-hero .highlight .card a{
  color:#efefef;
  text-decoration: underline;
}

.home .nav.nav-tabs-transparent {
  background-color: transparent!important;
  border-bottom: none;
}
.home .home-hero .highlight .card .nav-tabs a{
  color:#fff!important;
  text-decoration: none;
  opacity: .75;
  border: none !important;
  max-width: 80%;
  font-size: .9em;
}
.home .home-hero .highlight .card .nav.nav-tabs.nav-tabs-full li.active:after{
  background: transparent!important;
}
.home .home-hero .highlight .card .nav.nav-tabs-transparent li.active a{
  color:#fff!important;
  opacity: 1;
  border: none !important;
}
.home .nav-tabs li a .zmdi{
  opacity: 0;
  display: block;
  margin-bottom: .5em;
  transition: opacity 0.5s ease;
}
.home .nav-tabs li.active a .zmdi{
 opacity: 1;
}
/* /home */




.site-footer{
  color:#fff;
background-color: #24246C;    
background-image: linear-gradient(116.56505117707799deg, rgba(36, 36, 108, 1) 0%, rgba(151, 4, 64, 1) 100%);
padding: 15px 0 0;
}
.site-footer .footermenu a {
  color: #fff;
}
.ms-footer{
background-color:transparent;    
padding-top: 2em;
box-shadow: none;
}
.site-footer .footermenu h1,.site-footer .main-contact-info .name{
  font-weight:500;
}

@media (max-width: 767px) {
  .card.card-info .card-block{
    height: auto!important;
  }
  .site-footer .img-wrap-ec{
    max-width: 60%!important;
    margin: 0 auto 2em;

  }
  .site-footer .img-wrap-ern{
    max-width: 85%!important;
    margin: 0 auto;

  }
}

@media (min-width: 768px){
  .home .ms-header,.home  .ms-header a{
      color: #fff;
  }
  .home-hero .highlight .card{
  /*padding: 1em 4em 2em;  */
  padding: 0;
  }
  .box,.card{
    min-height: 250px;
  }
 
  .home-hero .tab-pane{
    
    height: 500px;
    max-height: 500px;
    /* min-height: 675px; */
    overflow: hidden;
  }
  .home-hero .tab-pane .col-md-6 {
    max-width: 500px;
  }
}

.card-hero>.card-block {
  
  max-width: 1000px;
  margin: 0 auto;
}